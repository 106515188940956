<template>
  <div align="center">
    <verifyPromo />
    <v-row justify="center" class="centrar" v-if="size > 960">
      <v-col sm="6" md="3">
        <div class="card-h" @click="$router.push('/inh')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(90vh - 100px )"
            contain
            src="https://trackandraces.com/dominio/tr/menu/mhipismopc.png"
          ></v-img>
        </div>
      </v-col>
      <v-col sm="6" md="3">
        <div class="card-h" @click="$router.push('/sport')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(90vh - 100px )"
            contain
            src="https://trackandraces.com/dominio/tr/menu/mparleypc.png"
          ></v-img>
        </div>
      </v-col>
      <v-col sm="6" md="3">
        <div class="card-h centrar" @click="$router.push('/casino')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(90vh - 100px )"
            contain
            src="https://trackandraces.com/dominio/tr/menu/mcasinopc.png"
          ></v-img>
        </div>
      </v-col>
      <v-col sm="6" md="3">
        <div class="card-h" @click="$router.push('/virtuales/dog8')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(90vh - 100px )"
            contain
            src="https://trackandraces.com/dominio/tr/menu/m247pc.png"
          ></v-img>
        </div>
      </v-col>
      

      <!--s <v-col  sm="6" md="3">
        <div class="card-h" v-if="this.$store.state.pais_user == 'Venezuela'" @click="$router.push('/inh')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(90vh - 112px )"
            contain
            src="https://trackandraces.com/img/inh.jpg"
          ></v-img
        </div>
      </v-col>  -->
    </v-row>
    <v-row justify="center" class="centrar" v-else>
      <v-col  sm="6" md="3">
        <div class="card-h mb-0" @click="$router.push('/inh')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(40vh - 100px )"
            contain
            src="https://trackandraces.com/dominio/tr/menu/mHipismom.png"
          ></v-img>
        </div>
      </v-col>
      <v-col class="mt-0 pt-0" sm="6" md="3">
        <div class="card-h mt-0 mb-0" @click="$router.push('/sport')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(40vh - 100px )"
            contain
            src="https://trackandraces.com/dominio/tr/menu/mparleym.png"
          ></v-img>
        </div>
      </v-col>
      <v-col class="mt-0 pt-0" sm="6" md="3">
        <div class="card-h mt-0 mb-0" @click="$router.push('/casino')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(40vh - 100px )"
            contain
            src="https://trackandraces.com/dominio/tr/menu/mcasinom.png"
          ></v-img>
        </div>
      </v-col>
      <v-col class="mt-0 pt-0" sm="6" md="3">
        <div class="card-h mt-0 mb-0" @click="$router.push('/virtuales/dog8')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(40vh - 100px )"
            contain
            src="https://trackandraces.com/dominio/tr/menu/m247m.png"
          ></v-img>
        </div>
      </v-col>

      <!--s <v-col  sm="6" md="3">
        <div class="card-h" v-if="this.$store.state.pais_user == 'Venezuela'" @click="$router.push('/inh')">
          <v-img
            style="border-radius: 6px"
            max-height="calc(90vh - 112px )"
            contain
            src="https://trackandraces.com/img/inh.jpg"
          ></v-img
        </div>
      </v-col>  -->
    </v-row>
    <template v-if="$store.state.idmoneda==2">
      <v-row justify="center">
        <v-dialog v-model="bancamiga" persistent max-width="400">
          <v-card flat style="background-color: transparent;">
            
            <v-img
              @click="bancamiga = false"
              src="https://apitr.xyz/dominio/tr/zelle2.png"
              
            ></v-img>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
//import { mapActions } from "vuex";
//import { mapState } from "vuex";
import axios from "axios";
import verifyPromo from "../components/VerifyPromo.vue";

//import { mapActions } from "vuex";
export default {
  components: {
    verifyPromo,
  },
  data() {
    return {
      gameUrl: null,
      overlay: true,
      isMobile: 0,
      link: "/inh",
      dialogm1: 0,
      dialog: true,
      dialogPreg: false,
      bancamiga: true,
    };
  },
  async created() {
    this.$store.state.pais_user = sessionStorage.getItem("pais_user");
    await axios({
      method: "post",
      url: `details`,
    }).then((response) => {
      this.$store.state.idpromodia = response.data.promodia.promo;
      this.$store.state.nbpromodia = response.data.promodia.nbpromo;
      //console.log(this.$store.state.nbpromodia);
    });
  },
  mounted() {
    this.getLink();
  },
  methods: {
    getLink() {
      if (this.$store.state.pais_user == "Venezuela") {
        this.link = "/inh";
      } else {
        this.link = "/bet";
      }
      //alert(this.link);
    },
  },
  computed: {
    size() {
      return this.$store.state.windowSize.x;
    },
  },
};
</script>

<style>
.card-h:hover {
  filter: grayscale(0.5);
  cursor: pointer;
  transition: ease-in-out 250ms;
  transform: scale(0.95);
}

.card-h {
  filter: none;
}

.centrar {
  display: flex;
  align-items: center;
}
</style>
